<template>
  <b-row class="mt-0 mt-lg-3">
    <b-col xl="3">
      <card :card="mod" :link="false" :seeMore="true" />
      <gauge
        v-if="isProfileLoaded"
        :title="$t('generic.modules.wellbeing.title')"
        :value="wellbeing.average ? wellbeing.average * 10 : wellbeing.average"
        class="mt-8"
        style="border-radius: 30px;"
        :formatter="
          val => {
            return wellbeing.average
              ? $n((Math.round(wellbeing.average * 10) / 10).toFixed(1)) + '/10'
              : '?/10';
          }
        "
      />
    </b-col>
    <b-col xl="9">
      <b-row>
        <b-col xl="12">
          <tabs
            :tabs="tabs"
            :title="$t('generic.modules.wellbeing.title')"
            v-if="tabs.length > 0"
          />
          <div v-else>
            <b-card
              class="card-custom gutter-b"
              body-class="p-0 d-flex flex-column"
              header-class="border-0 pt-5"
            >
              <template #header>
                <b-card-title class="font-weight-bolder">
                  <div class="card-label">
                    {{ $t("profile.wellbeing.help.title") }}
                  </div>
                </b-card-title>
              </template>
              <div class="card-body text-left">
                <p
                  v-for="(value, key) in $t(
                    'profile.wellbeing.help.description'
                  )"
                  :key="key"
                  v-html="value"
                />
              </div>
            </b-card>
            <empty-card type="profile.socialEngineering" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          xl="4"
          class="mb-8"
          v-for="interaction in visibleInteractions"
          :key="interaction.surveyEntityId"
        >
          <card :card="interaction" :date="interaction.date" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import { getUserActions } from "@/api/user/traits.api";

import Card from "@/components/profile/Card.component";
import Tabs from "@/components/training/Tabs.component";
import Gauge from "@/components/profile/Gauge.component";
import EmptyCard from "@/components/EmptyCard.component.vue";

export default {
  name: "Wellbeing",
  components: {
    Card,
    Tabs,
    Gauge,
    EmptyCard
  },
  methods: {
    ...mapActions("Profile", ["loadProfile"]),
    ...mapActions("Interactions", ["loadInteractions"]),
    loadRecommendations() {
      var params = {
        surveyTypes: [this.getConstant("SURVEY_KYMATIO_CLIMATE_GLOBAL")],
        groupDimensions: [this.getConstant("DIMENSION_GROUP_RECOMEND_CLIMATE")],
        actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
        entityType: this.getConstant("ENTITY_TYPE_USER")
      };
      getUserActions(params).then(res => {
        this.tabs = res.data.records;
      });
    }
  },
  data() {
    return {
      object: {},
      tabs: []
    };
  },
  computed: {
    ...mapGetters("Profile", ["isProfileLoaded", "modules", "wellbeing"]),
    ...mapGetters("User", ["archetypeSvg", "archetypeId"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Interactions", [
      "isInteractionsLoaded",
      "interactions",
      "getByTypes"
    ]),
    visibleInteractions() {
      return this.getByTypes([
        parseInt(this.getConstant("SURVEYTYPE_KYMATIO_CLIMA_PLUS"))
      ]);
    },
    mod() {
      return _.find(this.modules, e => {
        return e.id === "wellbeing";
      });
    },
    archetypeTabs() {
      return [
        {
          key: "description",
          title: this.$t("profile.tabs.description"),
          icon: "flaticon2-chat-1"
        },
        {
          key: "motivation",
          title: this.$t("profile.tabs.motivation"),
          icon: "flaticon2-drop"
        },
        {
          key: "work",
          title: this.$t("profile.tabs.work"),
          icon: "flaticon2-shield"
        },
        {
          key: "strengths",
          title: this.$t("profile.tabs.strengths"),
          icon: "fa fa-dumbbell"
        },
        {
          key: "challenges",
          title: this.$t("profile.tabs.challenges"),
          icon: "fa fa-mountain"
        }
      ];
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isProfileLoaded) this.loadProfile();
    if (this.isConstantsActive && !this.isInteractionsLoaded)
      this.loadInteractions();
    if (this.isConstantsActive) this.loadRecommendations();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadProfile();
        this.loadInteractions();
        this.loadRecommendations();
      }
    }
  }
};
</script>
