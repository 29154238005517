var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"mt-0 mt-lg-3"},[_c('b-col',{attrs:{"xl":"3"}},[_c('card',{attrs:{"card":_vm.mod,"link":false,"seeMore":true}}),(_vm.isProfileLoaded)?_c('gauge',{staticClass:"mt-8",staticStyle:{"border-radius":"30px"},attrs:{"title":_vm.$t('generic.modules.wellbeing.title'),"value":_vm.wellbeing.average ? _vm.wellbeing.average * 10 : _vm.wellbeing.average,"formatter":val => {
          return _vm.wellbeing.average
            ? _vm.$n((Math.round(_vm.wellbeing.average * 10) / 10).toFixed(1)) + '/10'
            : '?/10';
        }}}):_vm._e()],1),_c('b-col',{attrs:{"xl":"9"}},[_c('b-row',[_c('b-col',{attrs:{"xl":"12"}},[(_vm.tabs.length > 0)?_c('tabs',{attrs:{"tabs":_vm.tabs,"title":_vm.$t('generic.modules.wellbeing.title')}}):_c('div',[_c('b-card',{staticClass:"card-custom gutter-b",attrs:{"body-class":"p-0 d-flex flex-column","header-class":"border-0 pt-5"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-card-title',{staticClass:"font-weight-bolder"},[_c('div',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("profile.wellbeing.help.title"))+" ")])])]},proxy:true}])},[_c('div',{staticClass:"card-body text-left"},_vm._l((_vm.$t(
                  'profile.wellbeing.help.description'
                )),function(value,key){return _c('p',{key:key,domProps:{"innerHTML":_vm._s(value)}})}),0)]),_c('empty-card',{attrs:{"type":"profile.socialEngineering"}})],1)],1)],1),_c('b-row',_vm._l((_vm.visibleInteractions),function(interaction){return _c('b-col',{key:interaction.surveyEntityId,staticClass:"mb-8",attrs:{"xl":"4"}},[_c('card',{attrs:{"card":interaction,"date":interaction.date}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }